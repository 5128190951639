.services .services-title {
  font-size: 1.8rem;
}
@media screen and (min-width: 768px) {
  .services .services-title {
    font-size: 2.2rem;
  }
}
@media screen and (min-width: 1024px) {
  .services .services-title {
    font-size: 2.4rem;
  }
}

.services .link-arrow {
  color: var(--color-accent);
  font-size: 2rem;
  font-weight: bold;
  text-transform: uppercase;
}

.services .link-arrow::after {
  margin-left: 5px;
  transition: margin 0.15s;
}

.services .link-arrow:hover::after {
  margin-left: 10px;
}

@media screen and (min-width: 1024px) {
  .services .link-arrow {
    font-size: 1.5rem;
  }
}

.services .icon-container {
  background: var(--color-icon-background);
  border-radius: 100%;
  display: inline-flex;
  justify-content: center;
  align-items: center;

  height: 64px;
  width: 64px;
}

.services .icon-container--accent {
  background: var(--color-accent);
}

.services .block {
  --padding-vertical: 2rem;
  padding: var(--padding-vertical) 2rem;
}

.services .block__header {
  text-align: center;
  margin-bottom: 4rem;
}

.services .block__heading {
  margin-top: 0;
}

.services .container {
  margin: 0 auto;
  max-width: 1140px;
  /* not sure if this is necessay - it was hiding consultancy content abpove 1024px */
  /* overflow: hidden; */
}

.services .feature {
  gap: 1rem 1rem;
  margin: 5rem 0;
}

.services .feature:first-of-type {
  margin-top: 6rem;
}

.services .feature__heading {
  margin: 1rem 0;
}

.services .feature__image {
  width: 100%;
}

@media screen and (min-width: 768px) {
}

.services .reverse {
}

.services .leaf-badge-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.services .grid .leaf-badge {
  max-width: 200px;
  width: 100%;
  cursor: pointer;
}

.services .feature__content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.services .h3 {
  margin-left: 0;
}

.services .arrow {
  color: var(--color-accent);
  /* display: inline-block; */
  font-size: 4rem;
  padding: 0;
  margin-bottom: -1.5rem;
  margin-left: 3rem;
}
.services .arrow:hover {
  margin-left: 4.5rem;
  cursor: pointer;
}

.services .card-flip {
  max-width: 1240px;
  margin: 0 auto;
}

.services .card-flip .card {
  height: 327px;
  max-width: 580px;
  width: 100%;
  position: relative;
  perspective: 1000px;
  margin: 0 auto 5rem;
}

.services .card-flip .front,
.services .card-flip .back {
  height: 100%;
  width: 100%;
  transform-style: preserve-3d;
  position: absolute;
  backface-visibility: hidden;
  transition-duration: 0.5s;
}

.services .card-flip .back {
  background-size: 580px 327px;
  transform: rotateY(180deg);
  text-align: center;
}

.services .card-flip .flip:checked ~ .back {
  transform: rotateY(0deg);
}

.services .card-flip .flip:checked ~ .front {
  transform: rotateY(-180deg);
}

.services .card-flip .card:hover .back {
  transform: rotateY(0deg);
}

.services .card-flip .card:hover .front {
  transform: rotateY(-180deg);
}

.services .card-flip .card:active .back {
  transform: rotateY(0deg);
}

.services .card-flip .card:active .front {
  transform: rotateY(-180deg);
}

.services .card-flip .flip {
  position: absolute;
  left: 100%;
  top: 50%;
}

.services .p--cards {
  font-size: 13px;
  line-height: 24px;
}
