.training .card-flip .front-lets-talk {
  background-image: url(https://mary-anne-hodd.s3.eu-west-2.amazonaws.com/base/cards-training-1-lets-talk-words.jpg);
  background-size: 580px 327px;
  box-shadow: 0 0 20px 10px #f3f3f3;
}

.training .card-flip .front-childs-voice {
  background-image: url(https://mary-anne-hodd.s3.eu-west-2.amazonaws.com/base/cards-training-2-amplifying-the-childs-voice.jpg);
  background-size: 580px 327px;
  box-shadow: 0 0 20px 10px #f3f3f3;
}

.training .card-flip .front-beyond {
  background-image: url(https://mary-anne-hodd.s3.eu-west-2.amazonaws.com/base/cards-training-3-beyond-the-score.jpg);
  background-size: 580px 327px;
  box-shadow: 0 0 20px 10px #f3f3f3;
}

.training .card-flip .front-making-of-me {
  background-image: url(https://mary-anne-hodd.s3.eu-west-2.amazonaws.com/base/cards-training-4-the-making-of-me.jpg);
  background-size: 580px 327px;
  box-shadow: 0 0 20px 10px #f3f3f3;
}

.training .card-flip .front-relational-harm {
  background-image: url(https://mary-anne-hodd.s3.eu-west-2.amazonaws.com/base/cards-training-5-relational-healing.jpg);
  background-size: 580px 327px;
  box-shadow: 0 0 20px 10px #f3f3f3;
}

.training .card-flip .front-journey {
  background-image: url(https://mary-anne-hodd.s3.eu-west-2.amazonaws.com/base/cards-training-6-the-journey-beyond.jpg);
  background-size: 580px 327px;
  box-shadow: 0 0 20px 10px #f3f3f3;
}

.training .card-flip .front-healing-after-adversity {
  background-image: url(https://mary-anne-hodd.s3.eu-west-2.amazonaws.com/base/cards-training-7-healing-after-adversity.jpg);
  background-size: 580px 327px;
  box-shadow: 0 0 20px 10px #f3f3f3;
}

.training .card-flip .front-educational-achievements {
  background-image: url(https://mary-anne-hodd.s3.eu-west-2.amazonaws.com/base/cards-training-8-supporting-the-educational-achievements.jpg);
  background-size: 580px 327px;
  box-shadow: 0 0 20px 10px #f3f3f3;
}

.training {
  max-width: 1240px;
  margin: 0 auto;
}

.training ul {
  text-align: left;
}

.training .link-container {
  margin-top: 4rem;
}

.training .grid {
  margin-top: 8rem;
}

.training .story-head {
  max-width: 1000px;
  margin: 0 auto 2rem;
}

.training .story-head {
  text-align: center;
}

.training .enquire {
  margin: 0 0 6rem;
  display: flex;
  justify-content: center;
}

.training .photos {
  padding-top: 70px;
  padding-bottom: 70px;
}

.training .photos .img25 {
  max-width: 24.75%;
}

.training .photos .img50 {
  max-width: 49.5%;
  margin: 0 0.5%;
}

.training .hyperlink {
  margin: 0 auto;
}

.training .hyperlink > div {
  margin: 0 auto;
}

@media screen and (min-width: 1024px) {
  .training ul {
    margin-left: 7rem;
  }
}

.training .card .h3 {
  font-size: 2rem;
}

.card-training-mobile-container {
  height: auto;
  visibility: visible;
  display: block;
}

.card-training-desktop-container {
  height: 0;
  visibility: hidden;
  display: none;
}

@media screen and (min-width: 620px) {
  .card-training-desktop-container {
    height: auto;
    visibility: visible;
    display: block;
  }

  .card-training-mobile-container {
    height: 0;
    visibility: hidden;
    display: none;
  }
}
