/* menu */

.menu-panel {
  color: var(white);
  background-color: var(--color-primary);
  margin-bottom: 2rem;
  align-self: flex-end;
  border-radius: 0 0 0 1rem;
  position: absolute;
  margin-top: 230px;
  margin-right: -20px;
}

.dropdown-menu {
  list-style: none;
  padding: 1.8rem 3rem 1.8rem 2rem;
  margin: 0;
}

.dropdown-item {
  color: white;
}

.dropdown__link {
  color: white;
  padding: 0;
  margin: 0;
  width: auto;
  background-color: transparent;
  font-size: 1.6rem;
}

.dropdown__link:hover {
  border: none;
  font-size: 1.6rem;
  color: var(--color-secondary);
  cursor: pointer;
}
