/* .delete-subscriber-button {
  margin: 0;
  background: transparent;
  width: auto;
  margin-left: 3rem;
  cursor: pointer;
  margin: 0px;
} */

.delete-news .button--table {
  color: var(--color-accent);
  margin: 0;
  font-size: 1.5rem;
  line-height: 1.8rem;
  padding: 0;
}

.delete-news .button--table:hover {
  color: var(--color-primary);
}

.delete-news .button--delete-news:hover {
  background-color: var(--color-secondary);
}
