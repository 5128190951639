.footer-subscribe-form {
  width: 100%;
  background-color: var(--color-primary);
  color: white;
  padding: 10px 10px;
}

@media screen and (min-width: 550px) {
  .footer-subscribe-form {
    padding: 20px 20px;
  }
}

@media screen and (min-width: 768px) {
  .footer-subscribe-form {
    padding: 30px 30px;
  }
}

.footer-subscribe-form .grid {
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
}

.footer-subscribe-form form {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.footer-subscribe-form .button {
  align-self: left;
  margin: 15px;
}

.h2--subscribe {
  font-size: 2.3rem;
  line-height: 2.8rem;
  text-align: left;
  margin: 0;
}

.footer-subscribe-form label {
  text-align: left;
  margin-top: 1rem;
}

.footer-subscribe-form input {
  color: var(--color-primary);
}

.footer-subscribe-form form input::selection {
  background-color: var(--color-accent);
  -webkit-background-clip: text;
}

.footer-subscribe-form form input:-webkit-autofill {
  -webkit-text-fill-color: var(--color-primary);
  -webkit-background-clip: text;
}

.footer-subscribe-form form input::placeholder {
  color: var(--color-secondary);
}

.footer-subscribe-form form input:focus {
  outline: none;
}

/* Change the white to any color */
.footer-subscribe-form form input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset;
}

/* @media screen and (min-width: 768px) {
  .footer-subscribe-form .form__fields {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
  }
  .footer-subscribe-form label {
    text-align: left;
    display: inline;
  }
  .footer-subscribe-form input {
  }
} */

.footer-subscribe-form .feed-container {
  max-width: 500px;
  /* display: flex; */
  /* justify-content: center; */
  /* align-items: center; */
}

.footer-subscribe-form .ig-feed--side {
  display: block;
  margin: 0 auto;
}

.footer-subscribe-form .contact-form label {
  margin: 0.5rem 0 0.2rem;
}
