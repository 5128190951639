.unsubscribe {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  margin: 120px 0;
}

.unsubscribe form {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  width: 100%;
}

.unsubscribe input {
  margin: 0;
  font-size: 1.3rem;
  line-height: 1.8rem;
  width: 100%;
  max-width: 400px;
  padding: 0.5rem 1rem;
  border: 1px solid rgb(204, 204, 204);
  border-radius: 3px;
  /* margin-bottom: 2rem; */
}

@media screen and (min-width: 768px) {
  .unsubscribe input {
    font-size: 1.6rem;
    line-height: 2.1rem;
  }
}

.unsubscribe input {
  color: var(--color-primary);
}

.unsubscribe form input::selection {
  background-color: var(--color-accent);
  -webkit-background-clip: text;
}

.unsubscribe form input:-webkit-autofill {
  -webkit-text-fill-color: var(--color-primary);
  -webkit-background-clip: text;
}

.unsubscribe form input::placeholder {
  color: var(--color-secondary);
}

.unsubscribe form input:focus {
  outline: none;
}

/* Change the white to any color */
.unsubscribe form input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset;
}
