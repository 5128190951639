.privacy-policy {
  word-break: break-word;
}

.privacy-policy ul {
  margin-bottom: 2rem;
}

.privacy-policy a {
  color: var(--color-accent);
  text-decoration: none;
  font-weight: bold;
}

.privacy-policy a:link {
  color: var(--color-accent);
  text-decoration: none;
}

.privacy-policy a:active {
  color: var(--color-primary-text);
  text-decoration: none;
}

.privacy-policy a:hover {
  color: var(--color-primary-text);
  text-decoration: none;
}

.privacy-policy a:active {
  color: var(--color-primary-text);
  text-decoration: none;
}
