:root {
  --border-color: hsl(0, 0%, 85%);
}

.associates-table {
  /* background-color: rgb(185, 93, 93); */
  width: 100%;
  height: 100%;
  /* color: white; */
  /* background-color: hsl(0, 0%, 95%); */
  font-family: Inter, Arial, Helvetica, sans-serif;
  font-weight: 400;
  font-size: 1.3rem;
  overflow-y: scroll;
  text-align: center;
  padding: 30px;
}

.associates-table h1 {
  /* padding: 50px; */
  color: var(--color-primary);
  padding-bottom: 0px;
  /* border-style: solid 20px black; */
}

.associates-table img {
  overflow: hidden;
  border-radius: 50%;
  border: 1px solid black;
  max-width: 100%;
  aspect-ratio: 1;
  width: 120px;
  /* height: 120px;  */
  object-fit: cover;
  min-width: 120px;
  min-height: 120px;
  max-width: 120px;
  max-height: 120px;
}

.associates-table .link {
  color: var(--color-accent);
  text-decoration: none;
  font-weight: bold;
}

.associates-table .link:link {
  color: var(--color-accent);
  text-decoration: none;
}

.associates-table .link:active {
  color: var(--color-accent);
  text-decoration: none;
}

.associates-table .link:hover {
  color: var(--color-primary);
  text-decoration: none;
}

.table {
  color: var(--color-primary);
  /* width: calc(100% - 60px); */
  max-width: 100%;
  min-width: 100%;
  /* margin-bottom: 1rem; */
  /* margin-top: 30px; */
  /* margin: 30px 30px 1rem; */
  box-shadow: 0 0 10px 10px hsl(0, 0%, 95%);
  font-family: Inter, Arial, Helvetica, sans-serif;
  font-weight: 400;
  font-size: 1.3rem;
  /* border: 1px solid var(--color-primary); */
  border-collapse: collapse;
}

.table-container {
}

.table td + td {
  border-left: 1px solid var(--color-primary);
}

@media screen and (min-width: 768px) {
  .table {
    font-size: 1.4rem;
  }
}

@media screen and (min-width: 1024px) {
  .table {
    width: 100%;
    margin: 30px auto 1rem;
  }
}

.table th,
.table td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid var(--color-primary);
}

.table thead th {
  vertical-align: bottom;
  border-bottom: 1px solid var(--color-primary);
  background-color: var(--color-primary);
  color: white;
}

.table tbody {
  border-top: 1px solid var(--color-primary);
  /* background-color: hsl(0, 0%, 85%); */
}

/* .table .table {
}

.table-sm th,
.table-sm td {
  padding: 0.3rem;
}

.table-bordered {
  border: 1px solid var(--color-primary);
}

.table-bordered th,
.table-bordered td {
  border: 1px solid var(--color-primary);
}

.table-bordered thead th,
.table-bordered thead td {
  border-bottom-width: 2px;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: white;
}

.table-hover tbody tr:hover {
  background-color: white;
}

.table-active,
.table-active > th,
.table-active > td {
  background-color: hsl(0, 0%, 85%);
}

.table-hover .table-active:hover {
  background-color: hsl(0, 0%, 85%);
}

.table-hover .table-active:hover > td,
.table-hover .table-active:hover > th {
  background-color: hsl(0, 0%, 85%);
}

.table-success,
.table-success > th,
.table-success > td {
  background-color: #dff0d8;
}

.table-hover .table-success:hover {
  background-color: #d0e9c6;
}

.table-hover .table-success:hover > td,
.table-hover .table-success:hover > th {
  background-color: #d0e9c6;
}

.table-info,
.table-info > th,
.table-info > td {
  background-color: #d9edf7;
}

.table-hover .table-info:hover {
  background-color: #c4e3f3;
}

.table-hover .table-info:hover > td,
.table-hover .table-info:hover > th {
  background-color: #c4e3f3;
}

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #fcf8e3;
}

.table-hover .table-warning:hover {
  background-color: #faf2cc;
}

.table-hover .table-warning:hover > td,
.table-hover .table-warning:hover > th {
  background-color: #faf2cc;
}

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #f2dede;
}

.table-hover .table-danger:hover {
  background-color: #ebcccc;
}

.table-hover .table-danger:hover > td,
.table-hover .table-danger:hover > th {
  background-color: #ebcccc;
}

.thead-inverse th {
  color: #fff;
  background-color: #292b2c;
}

.thead-default th {
  color: #464a4c;
  background-color: #eceeef;
}

.table-inverse {
  color: #fff;
  background-color: #292b2c;
}

.table-inverse th,
.table-inverse td,
.table-inverse thead th {
  border-color: var(--color-primary);
}

.table-inverse.table-bordered {
  border: 0;
}

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}

.table-responsive.table-bordered {
  border: 0;
}

.buttons-td {
  display: flex;
}

.buttons-td > div > button {
  margin: 1px;
}

*/
