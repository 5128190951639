.upcoming-events {
  padding-top: 20px;
}

.upcoming-events .link {
}

.upcoming-events .title {
  padding-top: 20px;
}

.upcoming-events .event-info {
  display: flex;
  align-items: center;
  flex-direction: column;
  max-width: 90%;
}

@media screen and (min-width: 678px) {
  .upcoming-events .event-info {
    max-width: 45%;
  }
}

@media screen and (min-width: 1024px) {
  .upcoming-events .event-info {
    max-width: 33%;
  }
}

.upcoming-events img {
  width: 260px;
  max-width: 80%;
  border-radius: 10px;
  padding-top: 30px;
}

.upcoming-events .button--white-inverse {
  color: white;
}

.upcoming-events a {
  color: black;
  text-decoration: none;
  cursor: pointer;
  text-align: center;
}

.upcoming-events a:link {
  color: white;
  text-decoration: none;
}

.upcoming-events a:active {
  color: var(--color-accent);
  text-decoration: none;
}

.upcoming-events a:hover {
  color: black;
  text-decoration: none;
}

.upcoming-events a:active {
  color: var(--color-accent);
  text-decoration: none;
}

.upcoming-events .p--medium {
  font-weight: 400;
}

/* .upcoming-events .link {
  color: white;
}

.upcoming-events .link:link {
  color: white;
}
 */
.upcoming-events .events-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  max-width: 1000px;
  margin: 0 auto;
}
