.services-buttons-container {
  display: flex;
  justify-content: space-between;
  max-width: 500px;
  margin: 60px auto;
}

.services-button {
  width: 70px;
}

.services-button:hover {
  transform: scale(1.2);
  cursor: pointer;
  /* box-shadow: 0 0 20px 10px #f3f3f3; */
}

.services-buttons-container .services-link--text {
  padding-top: 0.8rem;
  font-weight: 700;
  font-size: 1.3rem;
  line-height: 1.6rem;
  margin: 0 auto;
  color: var(--color-accent);
  width: 100%;
  text-align: center;
}

.services-buttons-container .services-link--text:hover {
  cursor: pointer;
}

.services-buttons-container a {
  display: flex;
  flex-direction: column;
  align-items: center;
}
