.site-map ul {
  line-height: 3rem;
  margin: 0 0 40px 50px;
}

@media screen and (min-width: 1024px) {
  .site-map ul {
    /* margin: 120px 0 80px 50px; */
  }
}

.site-map .link {
  color: var(--color-accent);
  text-decoration: none;
  font-weight: bold;
}

.site-map .link:link {
  color: var(--color-accent);
  text-decoration: none;
}

.site-map .link:active {
  color: var(--color-primary);
  text-decoration: none;
}

.site-map .link:hover {
  color: var(--color-primary);
  text-decoration: none;
}

.site-map .link:active {
  color: var(--color-primary);
  text-decoration: none;
}
