.upload-form {
  gap: 1.5rem;
}

.upload__image {
  max-width: 300px;
  /* max-width: 80%; */
  max-height: 300px;
}

.upload__image--small {
  max-width: 80%;
  max-height: 300px;
}

.file-name-input {
  margin-left: 2rem;
}

.s3-upload a {
  color: var(--color-primary-text);
  text-decoration: none;
}

.s3-upload a:link {
  color: var(--color-primary-text);
  text-decoration: none;
}

.s3-upload a:active {
  color: var(--color-accent);
  text-decoration: none;
}

.s3-upload a:hover {
  color: var(--color-accent);
  text-decoration: none;
}

.s3-upload a:active {
  color: var(--color-accent);
  text-decoration: none;
}
