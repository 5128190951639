.guarantor-scheme {
  max-width: 900px;
}

.guarantor-scheme .leaf-badge-container {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 5rem;
}

.guarantor-scheme .leaf-badge {
  max-width: 250px;
}

.guarantor-scheme__brochure-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.guarantor-scheme__brochure-container img {
  width: 100%;
  max-width: 800px;
}

.guarantor-scheme .image-caption .link {
  color: var(--color-accent);
  /* text-decoration: none; */
  font-weight: bold;
}

.guarantor-scheme .image-caption .link:link {
  color: var(--color-accent);
  /* text-decoration: none; */
}

.guarantor-scheme .image-caption .link:active {
  color: var(--color-primary-text);
  /* text-decoration: none; */
}

.guarantor-scheme .image-caption .link:hover {
  color: var(--color-primary-text);
  /* text-decoration: none; */
}

.guarantor-scheme .image-caption .link:active {
  color: var(--color-primary-text);
  /* text-decoration: none; */
}

.guarantor-scheme .image-caption {
  margin: 3rem 0;
}

.guarantor-scheme .landlord-registry-container {
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
  margin-bottom: 20px;
}

@media screen and (min-width: 768px) {
  .guarantor-scheme .landlord-registry-container {
    margin-bottom: 30px;
  }
}

.guarantor-scheme .landlord-registry-container img {
  width: 100%;
  max-width: 500px;
}
