.blog-entry {
  padding-bottom: 60px;
}

.blog-entry h2 {
  text-align: center;
  max-width: 900px;
  margin: 0 auto 2rem;
}

.blog-entry .news-item {
  width: 95%;
  max-width: 800px;
  margin: 0 auto;
}

.file-links {
  max-width: 800px;
  margin: 0 auto;
  margin-top: 50px;
}

.file-links a {
  color: var(--color-accent);
  text-decoration: none;
  font-weight: bold;
  display: block;
  margin-bottom: 1rem;
}

.file-links a:link {
  color: var(--color-primary-text);
  text-decoration: none;
}

.file-links a:active {
  color: var(--color-primary-text);
  text-decoration: none;
}

.file-links a:hover {
  color: var(--color-primary-text);
  text-decoration: none;
}

.file-links a:active {
  color: var(--color-primary-text);
  text-decoration: none;
}
.news-item {
  word-break: break-word;
  color: var(--color-primary-text);
}

/* these override any formatting from copy paste */

.news-item h1 {
  color: white;
  font-family: "Times New Roman", Times, serif;
  text-transform: uppercase;
  font-size: 2.9rem;
  line-height: 3.45rem;
  font-weight: 600;
}

@media screen and (min-width: 550px) {
  .news-item h1 {
    font-size: 3.2rem;
    line-height: 3.8rem;
  }
}

@media screen and (min-width: 768px) {
  .news-item h1 {
    font-size: 3.5rem;
    line-height: 4.2rem;
  }
}

@media screen and (min-width: 1350px) {
  .news-item h1 {
    font-size: 4.8rem;
    line-height: 5.8rem;
  }
}

.news-item h2 {
  font-size: 3.1rem;
  line-height: 3.7rem;
  font-weight: 700;
  margin-bottom: 2rem;
}

.news-item h3 {
  font-size: 2.3rem;
  line-height: 2.8rem;
  font-weight: 700;
  margin-bottom: 2rem;
}

.news-item ol {
  margin-bottom: 2rem;
}

.news-item ul {
  margin-bottom: 2rem;
}

.news-item p,
.news-item span {
  word-break: break-word;
  color: var(--color-primary-text) !important;
  font-family: Arial, Helvetica, sans-serif !important;
  font-size: 1.6rem !important;
  line-height: 2.1rem !important;
}

/* to change anchor to match site colours */

.news-item a {
  color: var(--color-accent) !important;
  text-decoration: none;
  font-weight: bold;
}

.news-item a:link {
  color: var(--color-accent);
  text-decoration: none;
}

.news-item a:active {
  color: var(--color-primary-text);
  text-decoration: none;
}

.news-item a:hover {
  color: var(--color-primary-text);
  text-decoration: none;
}

.news-item a:active {
  color: var(--color-primary-text);
  text-decoration: none;
}

/* for spans within anchors */

.news-item a span {
  color: var(--color-accent) !important;
  text-decoration: none;
  font-weight: bold;
}

.news-item a span:link {
  color: var(--color-accent) !important;
  text-decoration: none;
}

.news-item a span:active {
  color: var(--color-primary-text) !important;
  text-decoration: none;
}

.news-item a span:hover {
  color: var(--color-primary-text) !important;
  text-decoration: none;
}

.news-item a span:active {
  color: var(--color-primary-text) !important;
  text-decoration: none;
}

.news-item img {
  padding: 2rem;
  max-width: 100% !important;
}

.news-item video {
  padding: 2rem;
  max-width: 100%;
}

.blog-entry .player-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 60px 0;
}

.blog-entry .react-player {
  width: 100%;
  max-width: 700px;
}

.blog-entry .main-image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 0 40px;
}

.blog-entry .main-image-container > img {
  width: 95%;
  max-width: 700px;
  max-height: 600px;
  border: 1px solid #000000;
}
