
.pagination-container {
  display: flex;
  list-style-type: none;
  margin: 30px auto 30px;
  justify-content: center;
  padding: 0;
  margin: 10px 0 40px;

  .pagination-item {
    padding: 0 12px;
    height: 32px;
    text-align: center;
    margin: auto 4px;
    // color: rgba(0, 0, 0, 0.87);
    display: flex;
    box-sizing: border-box;
    align-items: center;
    letter-spacing: 0.01071em;
    // border-radius: 16px;
    border-radius: 5px;
    // line-height: 1.43;
    font-size: 16px;
    min-width: 32px;
    // color: var(--color-secondary);
    color: black;
    font-weight: 500;
    border: 1px solid var(--color-secondary);

    &.dots:hover {
      background-color: transparent;
      cursor: default;
    }
    &:hover {
      // background-color: rgba(0, 0, 0, 0.04);
      background-color: var(--color-secondary);
      opacity: 0.7;
      color: white;
      cursor: pointer;
    }

    &.selected {
      // background-color: rgba(0, 0, 0, 0.08);
      background-color: var(--color-secondary);
      color: white;
      
    }


    &.disabled {
      pointer-events: none;
      color: gray;

      .arrow::before {
       /*  border-right: 0.12em solid rgba(0, 0, 0, 0.43);
        border-top: 0.12em solid rgba(0, 0, 0, 0.43); */
        border-right: 0.12em solid var(--color-primary);
        border-top: 0.12em solid var(--color-primary);
      }

      &:hover {
        background-color: transparent;
        cursor: default;
      }
    }
  }
}