.associates-new .h2 {
  text-align: center;
}

.associates-new .associate {
  max-width: 900px;
}

.associates-new .associates {
  display: flex;
  justify-content: space-evenly;
  align-items: flex-start;
  flex-wrap: wrap;
}

.associates-new .associate {
  display: flex;
  flex-direction: column;
  max-width: 300px;
  width: 100%;
  text-align: center;
  align-items: center;
  margin: 2rem;
}

.associates-new .associate__image {
  border-radius: 50%;
  /* border: 1px solid black; */
  height: 220px;
  width: 220px;
  margin-bottom: 2rem;
  aspect-ratio: 1;
  object-fit: cover;
}

.p--associate {
  line-height: 2.5rem;
}

.associates-new .associate__link {
  color: var(--color-primary-text);
  text-decoration: none;
}

.associates-new .associate__link:link {
  color: var(--color-primary-text);
  text-decoration: none;
}

.associates-new .associate__link:active {
  color: var(--color-accent);
  text-decoration: none;
}

.associates-new .associate__link:hover {
  color: var(--color-accent);
  text-decoration: none;
}

.associates-new .associate__link:active {
  color: var(--color-accent);
  text-decoration: none;
}

.associates-new .associates-carousel-container {
  margin: 30px auto 30px;
}
