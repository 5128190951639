.consultancy .consultancy-title {
  font-size: 1.8rem;
}
@media screen and (min-width: 768px) {
  .consultancy .consultancy-title {
    font-size: 2.2rem;
  }
}
@media screen and (min-width: 1024px) {
  .consultancy .consultancy-title {
    font-size: 2.4rem;
  }
}

.consultancy .link-container {
  margin-top: 8rem;
}

.consultancy .link-container {
  margin-top: 8rem;
}

.consultancy .focusAreas {
  width: 100%;
  display: flex;
  justify-content: center;
}

.consultancy .focusAreas .grid {
  max-width: 1000px;
}

.consultancy .focusAreas .h3 {
  width: auto;
}

.consultancy .testimonials {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.consultancy .testimonial {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
  padding-top: 50px;
}

.consultancy .testimonials .title {
  margin: 0;
  font-size: 2.2rem;
  font-weight: bold;
  padding-top: 30px;
}

.consultancy .testimonials .summary {
  margin: 0;
  font-size: 1.6rem;
}

.consultancy .testimonials .testimony {
  margin: 0;
  padding-top: 1rem;
}

.consultancy .testimonials .person {
  margin: 0;
  font-weight: 600;
  padding-top: 0.5rem;
  font-size: 1.6rem;
}

.consultancy .testimonials img {
  width: 100%;
  max-width: 250px;
  max-height: 250px;
}

.consultancy .link-container {
  width: 100%;
  margin: 4rem 0 2rem;
  display: flex;
  justify-content: center;
}

.consultancy .consultancy-flex-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.consultancy .consultancy-image {
  width: 100%;
  max-width: 80%;
  border-radius: 10px;
}
.consultancy .quote-icon-container {
  margin-top: 2rem;
}
