.admin-page {
  height: 100%;
}

.admin-page__content-nav {
  height: 100%;
  display: flex;
  /* justify-content: stretch; */
  min-height: 100vh;
}

.admin__nav {
  height: 100%;
  background-color: rgb(244, 244, 244);
  width: 250px;
  text-align: center;
  padding: 20px;
}

.admin__content {
  padding: 25px;
  width: 100%;
}

/* .admin__nav button { */
.admin__nav button {
  font-size: 1.7rem;
}

.admin__nav button:hover {
  color: var(--color-accent);
}

.admin__nav li {
  padding: 20px 10px;
  border-bottom: 1px solid var(--color-primary);
}
