:root {
  --clr-grey-8: hsl(210, 31%, 80%);
  --transition: all 0.3s linear;
  --spacing: 0.1rem;
  --radius: 0.25rem;
  --light-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  --dark-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
}
.slider {
  width: calc(100vw -5rem);
  margin: 5rem auto;
  max-width: 1170px;
  text-align: center;
}

@media screen and (min-width: 992px) {
  .slider {
    width: calc(95vw -5rem);
    /* width: 95vw; */
  }
}
.slider .title {
  text-align: center;
  margin-bottom: 2rem;
}

.slider .h3 {
  padding-left: 0;
}

.slider .title h2 {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
}
.slider .title span {
  font-size: 0.85em;
  margin-right: 1rem;
  font-weight: 700;
}
.slider .section-center {
  margin: 0 auto;
  width: 100%;
  text-align: center;
  position: relative;
  display: flex;
  overflow: hidden;
  height: 460px;
}
@media screen and (min-width: 550px) {
  .slider .section-center {
    height: 370px;
  }
}

@media screen and (min-width: 768px) {
  .slider .section-center {
    height: 340px;
  }
}

@media screen and (min-width: 1024px) {
  .slider .section-center {
    height: 290px;
  }
}

@media screen and (min-width: 1240px) {
  .slider .section-center {
    height: 260px;
  }
}

.slider .person-img {
  border-radius: 50%;
  margin-bottom: 1rem;
  width: 150px;
  height: 150px;
  object-fit: cover;
  border: 4px solid var(--clr-grey-8);
  box-shadow: var(--dark-shadow);
}
.slider article h4 {
  text-transform: uppercase;
  color: var(--color-primary-text);
  margin-bottom: 0.25rem;
  margin-left: 3rem;
  margin-right: 3rem;
}
.slider .title {
  text-transform: capitalize;
  margin-bottom: 0.75rem;
}
.slider .text {
  max-width: 90%;
  margin: 0 auto;
  margin-top: 2rem;
  line-height: 2;
}
.slider .icon {
  font-size: 3rem;
  margin-top: 1rem;
}
.slider .prev,
.slider .next {
  position: absolute;
  top: 30px;
  transform: translateY(-50%);
  width: 3rem;
  height: 3rem;
  display: grid;
  place-items: center;
  border-color: transparent;
  font-size: 2rem;
  border-radius: 50%;
  cursor: pointer;
  transition: var(--transition);
  color: white;
  background-color: var(--color-secondary);
}
.slider .prev:hover,
.slider .next:hover {
}
.slider .prev {
  left: 0;
}
.slider .next {
  right: 0;
}
@media (min-width: 800px) {
  .slider .text {
    max-width: 80%;
    line-height: 1.5;
  }
  .slider .prev,
  .slider .next {
    width: 4rem;
    height: 4rem;
    font-size: 2.5rem;
    top: 150px;
  }
}
.slider article {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: var(--transition);
}
.slider article.activeSlide {
  opacity: 1;
  transform: translateX(0);
}
.slider article.lastSlide {
  transform: translateX(-100%);
}
.slider article.nextSlide {
  transform: translateX(100%);
}
