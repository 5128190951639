.CTA-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.CTA-container .link {
  color: white;
  text-decoration: none;
  font-weight: bold;
}

.CTA-container .link:link {
  color: white;
  text-decoration: none;
}

.CTA-container .link:active {
  color: white;
  text-decoration: none;
}

.CTA-container .link:hover {
  color: black;
  text-decoration: none;
}
