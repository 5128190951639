.emailSubscriber .copy-icon {
  font-size: 50px;
  border-radius: 50%;
  padding: 1rem;
  background-color: var(--color-secondary);
  color: white;
}

.emailSubscriber .copy-icon:hover {
  background-color: var(--color-primary);
}
