.footer {
  background-color: var(--color-secondary);
  width: 100%;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 30px;
}

/* .p--footer {
  margin: 0;
} */

.footer p {
  margin: 0;
  color: white;
}

.footer span {
  color: white;
}

.footer__link {
  color: white;
  text-decoration: none;
  text-transform: uppercase;
}

.footer__link:link {
  color: white;
  text-decoration: none;
}

.footer__link:active {
  color: white;
  text-decoration: none;
}

.footer__link:hover {
  color: var(--color-primary);
  text-decoration: none;
}

.footer__link:active {
  color: white;
  text-decoration: none;
}

@media screen and (min-width: 1024px) {
  .footer p {
    margin: 0;
    text-align: right;
  }
}
