.add-main-comment-section {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.add-main-comment-form {
  font-size: 1.3rem;
  line-height: 1.8rem;
  width: 100%;
}

.add-main-comment-form label {
  display: block;
  margin: 0;
  margin-top: 2rem;
}

.add-main-comment-form .input--select {
  display: inline-block;
  margin-left: 2rem;
  padding: 0.5rem 1rem;
  border: 1px solid rgb(204 204 204);
  border-radius: 5px;
  color: var(--color-primary);
}

.add-main-comment-form .label--select {
  display: inline-block;
}

.add-main-comment-form input {
  margin: 0;
  font-size: 1.3rem;
  line-height: 1.8rem;
  width: 100%;
  padding: 0.5rem 1rem;
  border: 1px solid rgb(204, 204, 204);
  border-radius: 3px;
  /* margin-bottom: 2rem; */
}

.add-main-comment-form input::selection {
  background-color: var(--color-accent);
  -webkit-background-clip: text;
}

.add-main-comment-form input:-webkit-autofill {
  -webkit-background-clip: text;
  -webkit-text-fill-color: var(--color-primary);
}

.add-main-comment-form input::placeholder {
  color: var(--color-secondary);
}

.add-main-comment-form input:focus {
  outline: none;
}

.add-main-comment-form textarea {
  margin: 0;
  font-size: 1.3rem;
  line-height: 1.8rem;
  width: 100%;
  padding: 0.5rem 1rem;
  border: 1px solid rgb(204, 204, 204);
  border-radius: 3px;
  /* margin-bottom: 2rem; */
  min-height: 100px;
  font-family: inherit;
}

.add-main-comment-form textarea::selection {
  background-color: var(--color-accent);
  -webkit-background-clip: text;
}

.add-main-comment-form textarea:-webkit-autofill {
  -webkit-background-clip: text;
  -webkit-text-fill-color: var(--color-primary);
}

.add-main-comment-form textarea::placeholder {
  color: var(--color-secondary);
}

.add-main-comment-form textarea:focus {
  outline: none;
}

.input__error-message {
  color: var(--color-secondary);
  font-size: 1.4rem;
  /* padding: 1rem 2rem; */
  border-radius: 15px;
  margin-bottom: 0;
}

.add-main-comment-form .add-main-comment-form__checkbox {
  display: inline;
  width: 15px;
  margin-bottom: 0;
}

.add-main-comment-form .add-main-comment-form__checkbox-label {
  display: inline;
  margin-left: 10px;
}

.add-main-comment-form .button {
  display: block;
  margin-top: 30px;
  margin-bottom: 50px;
  margin: 0 auto;
}

.add-main-comment-form .button--submit-comment {
  margin: 2rem auto;
}

.upload-files--flex {
  display: flex;
  /* justify-content: space-around; */
}

@media screen and (min-width: 760px) {
  .add-main-comment-form {
    font-size: 1.6rem;
    line-height: 2.1rem;
  }
  .add-main-comment-form input {
    font-size: 1.6rem;
    line-height: 2rem;
  }
  .add-main-comment-form textarea {
    font-size: 1.6rem;
    line-height: 2rem;
  }
}
