.button-container {
}

.delete-comment .button--table {
  color: var(--color-primary);
  margin: 0;
  font-size: 1.5rem;
  line-height: 1.8rem;
  padding: 0;
  cursor: pointer;
}

.delete-comment .button--table:hover {
  color: var(--color-accent);
}

.button--delete-warning {
  padding: 1rem 2.2rem;
  min-width: 100px;
}
