.afit .afit-banner {
  width: 100%;
}

.afit .block1 {
  margin: 0 auto;
  margin-top: 30px;
  max-width: 1000px;
}

.afit .block1 .p--bold {
  margin-top: 20px;
}

.afit .block2 {
  margin: 0 auto;
  margin-top: 30px;
  max-width: 1000px;
  background-color: #233451;
  color: white;
  padding: 3%;
}
.afit .block2 ul {
  max-width: 900px;
}

.afit .block2 ul li {
  margin: 2rem 0;
}
.afit .block2 ol li {
  margin: 0 0;
}

.afit .block2 ol {
  max-width: 800px;
}

.afit .block2 .link {
  color: white;
}
.afit .block2 .link:hover {
  color: var(--color-accent);
}

.afit .afit-logo {
  width: 100%;
  max-width: 60%;
  margin: 0 auto;
}

.afit .afit-logo {
  width: 100%;
  max-width: 60%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
}

@media screen and (min-width: 768px) {
  .afit .afit-logo {
    max-width: 400px;
  }
}

.afit a {
  color: var(--color-accent);
  text-decoration: none;
  font-weight: bold;
}

.afit a:link {
  color: var(--color-accent);
  text-decoration: none;
}

.afit a:hover {
  color: var(--color-primary-text);
  text-decoration: none;
}

.afit a:active {
  color: var(--color-primary-text);
  text-decoration: none;
}

.afit .afit-banner_d {
  height: 0;
  visibility: hidden;
  display: none;
}

.afit .afit-banner_m {
  height: auto;
  visibility: visible;
  display: block;
}

@media screen and (min-width: 768px) {
  .afit .afit-banner_m {
    height: 0;
    visibility: hidden;
    display: none;
  }

  .afit .afit-banner_d {
    height: auto;
    visibility: visible;
    display: block;
  }
}
