/* @import url("https://fonts.googleapis.com/css2?family=Bungee+Shade&family=Vast+Shadow&display=swap"); */

/* .download-button {
  border: 2px solid white;
  font-size: 10px;
  font-weight: bold;
  width: fit-content;
  background-color: transparent;
}

.download-button:hover {
  background-color: hsl(0, 0%, 20%);
}

.input-id {
  width: 5%;
  min-width: 50px;
  margin-left: 10px;
}

.div-introduceid {
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
} */

.create-news {
  margin-top: 40px;
}

.create-news input {
  display: inline-block;
  margin-left: 2rem;
  margin-right: 2rem;
  padding: 0.5rem 1rem;
  border: 1px solid rgb(204 204 204);
  border-radius: 5px;
  color: var(--color-primary);
  min-width: 40%;
}

.create-news input::selection {
  background-color: var(--color-accent);
  -webkit-background-clip: text;
}

.create-news input:-webkit-autofill {
  -webkit-background-clip: text;
  -webkit-text-fill-color: var(--color-primary);
}

.create-news input::placeholder {
  color: var(--color-secondary);
}

.create-news input:focus {
  outline: none;
}
