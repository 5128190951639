.cards-keynotes-desktop {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  width: 1300px;
  max-width: 1300px;
}

.cards-keynotes-desktop .card {
  margin: 3rem 4rem 3rem !important;
}

.single-card-grid .card {
  margin: 1.5rem auto;
}
