.keynotes .card-flip .front-navigating {
  background-image: url(https://mary-anne-hodd.s3.eu-west-2.amazonaws.com/base/cards-keynotes-1-navigating-the-educational-system.jpg);
  background-size: 580px 327px;
  box-shadow: 0 0 20px 10px #f3f3f3;
}

.keynotes .card-flip .front-trauma-aware {
  background-image: url(https://mary-anne-hodd.s3.eu-west-2.amazonaws.com/base/cards-keynotes-2-trauma-aware-care.jpg);
  background-size: 580px 327px;
  box-shadow: 0 0 20px 10px #f3f3f3;
}

.keynotes .card-flip .front-voices-heard {
  background-image: url(https://mary-anne-hodd.s3.eu-west-2.amazonaws.com/base/cards-keynotes-3-voices-heard.jpg);
  background-size: 580px 327px;
  box-shadow: 0 0 20px 10px #f3f3f3;
}

.keynotes .card-flip .front-daring-to-dream {
  background-image: url(https://mary-anne-hodd.s3.eu-west-2.amazonaws.com/base/cards-keynotes-4-daring-to-dream.jpg);
  background-size: 580px 327px;
  box-shadow: 0 0 20px 10px #f3f3f3;
}

.keynotes .card-flip .front-bridges {
  background-image: url(https://mary-anne-hodd.s3.eu-west-2.amazonaws.com/base/cards-keynotes-5-building-bridges.jpg);
  background-size: 580px 327px;
  box-shadow: 0 0 20px 10px #f3f3f3;
}

.keynotes .card-flip {
  margin-top: 50px;
}

.keynotes .photos {
  margin: 0 auto 4rem;
  width: 100%;
  max-width: 1300px;
  /* margin-bottom: 6rem; */
  display: flex;
  flex-wrap: wrap;
}

.keynotes .img50 {
  width: 100%;
  min-width: 350px;
  margin-top: 20px;
}

.keynotes .keynotes-image-container {
  width: 100%;
  max-width: 500px;
  padding: 2rem 0 3rem;
  margin: 0 auto;
}

.keynotes .keynotes-image {
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
}

.keynotes .link-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.keynotes .hyperlink {
  margin: 0 auto;
}

.keynotes .hyperlink > div {
  margin: 0 auto;
}

@media screen and (min-width: 620px) {
  .keynotes .photos {
    margin-bottom: 6rem;
  }
}

@media screen and (min-width: 790px) {
  .keynotes .img50 {
    width: 50%;
    /* min-width: 500px; */
    margin-top: 0;
  }
}

.keynotes .card .h3 {
  font-size: 2rem;
}

.cards-keynotes-mobile-container {
  height: auto;
  visibility: visible;
  display: block;
}

.cards-keynotes-desktop-container {
  height: 0;
  visibility: hidden;
  display: none;
}

@media screen and (min-width: 620px) {
  .cards-keynotes-desktop-container {
    height: auto;
    visibility: visible;
    display: block;
  }

  .cards-keynotes-mobile-container {
    height: 0;
    visibility: hidden;
    display: none;
  }
}
