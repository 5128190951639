.home-picture-mary {
  display: flex;
  justify-content: center;
  width: 100%;
  border-radius: 5px;
  margin-bottom: 20px;
}

.home-picture-mary img {
  border-radius: 10px;
}

.home-picture-mary .block {
  width: 100%;
  background-color: #adbf8e;
  background-color: var(--color-secondary);
  margin: 5% 0;
}

.home-picture-mary .block--left {
  border-radius: 15px 0 0 15px;
}

.home-picture-mary .block--right {
  border-radius: 0 15px 15px 0;
}

.home-picture-mary img {
  max-width: 100%;
  width: 100%;
  max-height: 300px;
}

.my.home -services img {
  width: 100%;
  max-width: 100%;
}

.main-image {
  background-color: var(--color-secondary);
  min-height: 300px;
}

.badge-leaf {
  border-radius: 50%;
  background-color: var(--color-primary);
  padding: 4px;
}
.badge-leaf__container2 {
  border-radius: 50%;
  background-color: white;
  padding: 10px;
  width: 50px;
  height: 50px;
}
.badge-leaf__container3 {
  border-radius: 50%;
  background-color: var(--color-primary);
  padding: 20px;
  width: 50px;
  height: 50px;
}

.leaf-badge {
  max-width: 100px;
}

.home .services {
  border-radius: 10px;
  padding: 3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0 0 20px 10px #f3f3f3;
  margin: 4rem 0;
}

@media screen and (min-width: 768px) {
  .home .services {
    flex-direction: row;
    align-items: center;
  }
}

.home .services .image {
  width: 250px;
  height: 250px;
  min-width: 250px;
  min-height: 250px;
  border-radius: 50%;
  background-size: 300px 300px;
  background-position: center;
  border: 2px solid var(--color-accent);
}

.home .services .services__text {
  padding: 3rem;
}

.home .services .services__text h4 {
  margin: 0;
  margin-bottom: 1rem;
}

.home .services-grid img {
  width: 100%;
}

.home .services-grid p {
  cursor: pointer;
  margin-top: 2rem;
}

.home .image-text__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin: 10px;
  cursor: pointer;
}

.home .image-icon {
  max-width: 200px;
  margin-bottom: 10px;
}

.home .home__video-container {
  width: 100%;
  display: flex;
  justify-content: center;
}

.home .home__video-content {
  max-width: 550px;
}
