.block-plans .check {
  color: var(--color-accent);
  padding-top: 0.5rem;
  font-size: 2rem;
  margin-right: 1.6rem;
}

/* Badges */

.block-plans .badge {
  border-radius: 20px;
  font-size: 2rem;
  font-weight: 600;
  padding: 0.5rem 2rem;
  /* stops badge text from wrapping to multiple lines */
  white-space: nowrap;
}

.block-plans .badge--primary {
  background: var(--color-primary);
  color: white;
}

.block-plans .badge--secondary {
  background: var(--color-secondary);
  color: white;
}

.block-plans .badge--small {
  font-size: 1.6rem;
  color: white;
  padding: 0.5rem 1.5rem;
}

@media screen and (min-width: 1024px) {
  .block-plans .badge {
    font-size: 1.5rem;
  }

  .block-plans .badge {
    font-size: 1.2rem;
  }
}

/* Lists */

.block-plans .list {
  /* to remove the default bullet point */
  list-style: none;
  /* to remove the indent that is set by default */
  padding-left: 0;
}

.block-plans .list--inline .list__item {
  display: inline-block;
  margin-right: 2rem;
  padding-bottom: 0.5rem;
}

.block-plans .list--tick {
  /* list-style-image: url(./assets/images/tick.svg); */
  padding-left: 0.5rem;
  color: var(--color-primary-text);
  font-size: 1.4rem;
}

/* This is called nesting, we should try to avois it because it is very specific but it has its uses */
/* Here the styles are applied to the list items with class of list__item within the list element with class list--tick */
/* So, for lists with a tick skin, our items should have ... */
.block-plans .list--tick .list__item {
  padding-left: 0.5rem;
  margin-bottom: 1rem;
}

@media screen and (min-width: 1024px) {
  .block-plans .list--tick .list__item {
    padding-left: 0;
  }
}

/* Buttons */

.block-plans .btn {
  border-radius: 40px;
  border-style: none;
  cursor: pointer;
  font-size: 1.8rem;
  margin: 1rem 0;
  /* removes the blue outline around the button */
  outline: 0;
  /* horizontal padding set to 4% of viewport width insread of 3rem to adjust better to very small screens */
  padding: 1.5rem 4vw;
  text-align: center;
  text-transform: uppercase;
  white-space: nowrap;
}

.block-plans .btn--block {
  width: 100%;
  display: inline-block;
}

/* .btn--expandable {
    padding: 2rem 3rem;
  } */

.block-plans .btn--outline {
  background: transparent;
  border: 2px solid var(--color-primary);
  color: var(--color-primary-text);
}

.block-plans .btn--outline:hover {
  background: var(--color-secondary);
  color: white;
}

.block-plans .btn--primary {
  background: var(--color-primary);
  color: white;
}

.block-plans .btn--primary:hover {
  /* using SASS or LESS we can use lighten (or i guess darken) to add changes here instead of polluting :route */
  /* background: lighten(primary, 10%); */
  background: var(--color-primary);
}

.block-plans .btn--primary--inverse {
  background: white;
  color: var(--color-primary);
  border: 2px solid var(--color-primary-text);
}

.block-plans .btn--primary--inverse:hover {
  /* using SASS or LESS we can use lighten (or i guess darken) to add changes here instead of polluting :route */
  /* background: lighten(primary, 10%); */
  background: var(--color-primary);
}

.block-plans .btn--secondary {
  background: var(--color-secondary);
  color: white;
}

.block-plans .btn--secondary:hover {
  background: var(--color-secondary-hover);
}

.block-plans .btn--accent {
  background: var(--color-accent);
  color: white;
}

.block-plans .btn--accent:hover {
  background: var(--color-accent-hover);
}

.block-plans .btn--stretched {
  padding-left: 6rem;
  padding-right: 6rem;
}

/* if there is an icon within a button then the icon styles should be: */
.block-plans .btn .icon {
  width: 2rem;
  height: 2rem;
  margin-right: 1rem;
  vertical-align: middle;
}

@media screen and (min-width: 1024px) {
  .block-plans .btn {
    font-size: 1.5rem;
    padding: 2rem 3rem;
  }
}

/* Cards */

.block-plans .card {
  border-radius: 7px;
  /* values: horizontal offset (0), vertical offset (0), blur/softness (20px), spread (10px), color */
  box-shadow: 0 0 20px 10px #f3f3f3;
  /* removes the sharp corners at the top of the header because without this the header is larger than the card element */
  overflow: hidden;
  margin: 10px;
}

.block-plans .card__header,
.block-plans .card__body {
  padding: 2rem 3rem;
}

/* if we have a .card__header inside a .card--primary element then the .card__header should be styled: */
.block-plans .card--primary .card__header {
  background: var(--color-primary);
  color: white;
}

.block-plans .card--secondary .card__header {
  background: var(--color-secondary);
  color: white;
}

.block-plans .card--secondary .badge--secondary {
  background: var(--color-primary);
}

.block-plans .card--primary .badge--primary {
  background: var(--color-accent);
}

@media screen and (min-width: 1024px) {
  .block-plans .card {
    margin: 30px;
  }
}

/* Plans */

.block-plans .plan {
  transition: transform 0.2s ease-out;
}

.block-plans .plan__name {
  color: white;
  font-weight: 500;
  font-size: 2.4rem;
  margin: 0;
}

.block-plans .plan__price {
  font-size: 6rem;
}

.block-plans .plan__billing-cycle {
  font-size: 2.4rem;
  font-weight: 300;
  margin-right: 1rem;
  opacity: 0.8;
}

.block-plans .plan__description {
  /* keeps the text on a new line even for wide screens */
  display: block;
  font-size: 2rem;
  font-weight: 300;
  /* designers often play with letter-spacing, so it's good to train your eye for when working with designs */
  letter-spacing: 1px;
}

.block-plans .plan .list__item {
  letter-spacing: 1px;
  /* margin-bottom: 2rem; */
}

.block-plans .plan--popular .card__header {
  position: relative;
}

/* .block-plans .plan--popular .card__header::before {
  content: url(./assets/images/popular.svg);
  width: 40px;
  display: inline-block;
  position: absolute;
  top: -6px;
  right: 5%;
} */

.block-plans .plan:hover {
  transform: scale(1.05);
}

@media screen and (min-width: 1024px) {
  .block-plans .plan--popular {
    transform: scale(1.1);
  }

  .block-plans .plan--popular:hover {
    transform: scale(1.15);
  }

  .block-plans .plan__name {
    font-size: 1.4rem;
  }
  .block-plans .plan__price {
    font-size: 5rem;
  }

  .block-plans .plan__billing-cycle {
    font-size: 1.6rem;
  }

  .block-plans .plan__description {
    font-size: 1.7rem;
  }
}

/* Blocks */

.block-plans .block {
  /* variables can also be declared here, no only in route, accessible in all .block classes */
  --padding-vertical: 6rem;
  /* the variable can then be used in other situations */
  padding: var(--padding-vertical) 2rem;
}

.block-plans .block__header {
  text-align: center;
  margin-bottom: 4rem;
}

.block-plans .block__heading {
  margin-top: 0;
}

/* Plans Block */

.block-plans .block-plans .grid {
  /* row gap : column gap */
  gap: 8rem 4rem;
}

.block-plans .block-plans .card {
  max-width: 500px;
  margin: 0 auto;
}
