.home-page {
  width: 100%;
  height: fit-content;
  /* overflow: hidden; */
  overflow-y: scroll;
}

.home-page__header {
  /* justify-self: stretch; */
}

.home-page__main {
  /* display: flex; */
  width: 100%;
}

/* .home-page__navbar--side {
  display: none;
}

.home-page__navbar--top {
  width: 100%;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: rgb(240, 239, 239);
  overflow-x: hidden;
} */

.web-share {
  /*   position: fixed;
  float: right; */
  background-color: var(--color-secondary);
  border-radius: 50%;
  color: white;
  width: 60px;
  height: 60px;
  cursor: pointer;

  position: fixed;
  /* top: calc(100vh - 100px); */
  bottom: 110px;
  right: 50px;
}

.web-share:hover {
  opacity: 0.6;
}

.web-share button {
  color: white;
  padding-left: 18px;
  padding-top: 15px;
  font-size: 30px;
}

.title--main {
  background-color: var(--color-light-grey);
  padding: 20px;
  padding-top: 120px;
}

@media screen and (min-width: 768px) {
  .title--main {
    background-color: var(--color-light-grey);
    padding: 25px;
    padding-top: 120px;
    min-width: 500px;
    justify-self: center;
  }
}
@media screen and (min-width: 1024px) {
  .title--main {
    background-color: var(--color-light-grey);
    padding: 45px 20px;
    justify-self: center;
  }
}

.social-media-icons-container {
  padding: 20px;
  text-align: right;
  background-color: var(--color-light-grey);
  align-self: center;
}

.home-page__header .top-bar-container {
  display: flex;
  justify-content: space-between;
}

@media screen and (min-width: 1024px) {
  .home-page {
    /* display: flex; */
    justify-content: stretch;
  }
  .home-page__main {
    /* padding: 40px; */
    /* margin-left: 300px; */
  }
}

/* old logo type */
/* .top-bar-container .logo {
  min-width: 150px;
  max-width: 150px;
  padding: 2rem 0 0 4rem;
}

.top-bar-container .logo img {
  width: 150px;
  min-width: 150px;
  max-width: 150px;
} */
.top-bar-container .logo {
  min-width: 110px;
  max-width: 110px;
  padding: 1rem 0 0 2rem;
}
.top-bar-container .logo img {
  width: 110px;
  min-width: 110px;
  max-width: 110px;
}

.home-page .logo {
  width: 200px;
}
