.services-overview .column-two {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  padding: 10%;
}

.services-overview .description {
  max-width: 800px;
  margin: 40px auto 20px;
}

.myworkhas {
  background-color: var(--color-secondary);
  padding-top: 2rem;
  padding-bottom: 2rem;
  margin: 2rem auto;
}

.myworkhas li {
  padding: 0 2rem;
  color: white;
}

.services-overview .grid {
  padding-top: 2rem;
}

.services-overview .service__link {
  color: black;
  text-decoration: none;
}

.services-overview .service__link:link {
  color: black;
  text-decoration: none;
}

.services-overview .service__link:active {
  color: var(--color-accent);
  text-decoration: none;
}

.services-overview .service__link:hover {
  color: var(--color-accent);
  text-decoration: none;
}

.services-overview .service__link:active {
  color: var(--color-accent);
  text-decoration: none;
}
