.three-cards {
}

.three-cards .card {
  border-radius: 4px;
  box-shadow: 0 0 20px 10px #f3f3f3;
  padding: 20px;
  text-align: center;
}

.three-cards .card:hover {
  transform: scale(1.2);
}

@media screen and (min-width: 768px) {
  .three-cards .card {
    max-width: 90%;
  }
}

/* ========== TYPE 2 ========= */

.three-cards--details .card {
  border-radius: 4px;
  box-shadow: 0 0 20px 10px #cfcfcf;
  text-align: center;
}

.three-cards--details .card:hover {
  transform: scale(1.2);
}

@media screen and (min-width: 768px) {
  .three-cards--details .card {
    max-width: 90%;
  }
}

.three-cards--details .header {
  background-color: var(--color-secondary);
  color: white;
  border-radius: 4px 4px 0 0;
}

.three-cards--details .content {
  background-color: white;
  color: var(--color-primary-text);
  border-radius: 0 0 4px 4px;
}
