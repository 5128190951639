.blog .articles {
  justify-content: center;
}

.blog .article {
  display: flex;
  flex-direction: column;
  max-width: 1000px;
  text-align: center;
  align-items: center;
  margin: 2rem;
}

.blog .article__image {
  border-radius: 10px;
  max-width: 100%;
  margin-bottom: 2rem;
  aspect-ratio: 1.5;
  object-fit: cover;
  max-height: 350px;
  box-shadow: 0 0 20px 10px #f3f3f3;
}

.blog .article__image--small {
  border-radius: 10px;
  max-width: 100%;
  margin-bottom: 2rem;
  aspect-ratio: 1.5;
  object-fit: cover;
  max-height: 230px;
  box-shadow: 0 0 20px 10px #f3f3f3;
}

.blog .p--article {
  line-height: 2rem 0.5rem;
  margin-bottom: 2rem;
}

.blog .no-search-results {
  margin: 3rem auto;
}

.blog .article__link {
  color: var(--color-primary-text);
  text-decoration: none;
}

.blog .article__link:link {
  color: var(--color-primary-text);
  text-decoration: none;
}

.blog .article__link:active {
  color: var(--color-accent);
  text-decoration: none;
}

.blog .article__link:hover {
  color: var(--color-accent);
  text-decoration: none;
}

.blog .article__link:active {
  color: var(--color-accent);
  text-decoration: none;
}

.blog .intro {
  padding-top: 50px;
}

.blog .articles-flex-container {
  display: flex;
  flex-wrap: wrap;
  max-width: 1400px;
  justify-content: space-around;
  margin: 0 auto;
}

/* to m odify the button style to sit on top of image */
.blog .read-more {
  margin-top: -3rem;
  margin-bottom: 2rem;
  font-size: 16px;
  line-height: 21px;
  width: 100%;
  max-width: none;
  border: none;
  box-shadow: 0 10px 10px -10px #f3f3f3;
}

/* max height of image x aspect ratio */
.blog .read-more--large {
  max-width: 525px;
}

.blog .read-more--small {
  max-width: 345px;
}

@media screen and (min-width: 1024px) {
  .blog .articles-flex-container .article {
    max-width: 300px;
  }
}
