.edit-subscriber-form {
  font-size: 1.9rem;
  line-height: 2.7rem;
}

.edit-subscriber-form label {
  display: block;
  margin: 0;
  margin-top: 2rem;
}

.edit-subscriber-form .input--select {
  display: inline-block;
  margin-left: 2rem;
  padding: 0.5rem 1rem;
  border: 1px solid rgb(204 204 204);
  border-radius: 5px;
  color: var(--color-primary);
}

.edit-subscriber-form .label--select {
  display: inline-block;
}

.edit-subscriber-form input {
  margin: 0;
  font-size: 1.9rem;
  line-height: 2rem;
  width: 100%;
  padding: 0.5rem 1rem;
  border: 1px solid rgb(204, 204, 204);
  border-radius: 3px;
  /* margin-bottom: 2rem; */
}

.edit-subscriber-form input::selection {
  background-color: var(--color-accent);
  -webkit-background-clip: text;
}

.edit-subscriber-form input:-webkit-autofill {
  -webkit-background-clip: text;
  -webkit-text-fill-color: var(--color-primary);
}

.edit-subscriber-form input::placeholder {
  color: var(--color-secondary);
}

.edit-subscriber-form input:focus {
  outline: none;
}

.edit-subscriber-form textarea {
  margin: 0;
  font-size: 1.9rem;
  line-height: 2rem;
  width: 100%;
  padding: 0.5rem 1rem;
  border: 1px solid rgb(204, 204, 204);
  border-radius: 3px;
  /* margin-bottom: 2rem; */
  min-height: 100px;
  font-family: inherit;
}

.edit-subscriber-form textarea::selection {
  background-color: var(--color-accent);
  -webkit-background-clip: text;
}

.edit-subscriber-form textarea:-webkit-autofill {
  -webkit-background-clip: text;
  -webkit-text-fill-color: var(--color-primary);
}

.edit-subscriber-form textarea::placeholder {
  color: var(--color-secondary);
}

.edit-subscriber-form textarea:focus {
  outline: none;
}

.input__error-message {
  color: var(--color-secondary);
  font-size: 1.4rem;
  /* padding: 1rem 2rem; */
  border-radius: 15px;
  margin-bottom: 0;
}

.edit-subscriber-form .edit-subscriber-form__checkbox {
  display: inline;
  width: 15px;
  margin-bottom: 0;
}

.edit-subscriber-form .edit-subscriber-form__checkbox-label {
  display: inline;
  margin-left: 10px;
}

.edit-subscriber-form .button {
  display: block;
  margin-top: 30px;
  margin-bottom: 50px;
}

.upload-files--flex {
  display: flex;
  /* justify-content: space-around; */
  flex-wrap: wrap;
  gap: 20px;
}
