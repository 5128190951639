/* Cards */

.block-plans-vision .card {
  border-radius: 7px;
  overflow: hidden;
  margin: 10px;
}

.block-plans-vision .card__header {
  display: flex;
  justify-content: space-around;
}

.block-plans-vision .card__header,
.block-plans-vision .card__body {
  /* padding: 2rem 1rem; */
}

.block-plans-vision .card__body p {
  padding-left: 2rem;
}

.block-plans-vision h4 {
  text-align: center;
}

/* Plans */

.block-plans-vision .plan {
  transition: transform 0.2s ease-out;
}

.block-plans-vision .plan:hover {
  transform: scale(1.05);
}

/* Blocks */

.block-plans-vision .block {
}

.block-plans-vision .block__header {
  text-align: center;
  margin-bottom: 4rem;
}

.block-plans-vision .block__heading {
  margin-top: 0;
}

/* Plans Block */

.block-plans-vision .block-plans-vision .grid {
  gap: 8rem 4rem;
}

.block-plans-vision .block-plans-vision .card {
  max-width: 500px;
  margin: 0 auto;
}

/* NEW */

.block-plans-vision .vision .image {
  width: 250px;
  height: 250px;
  min-width: 250px;
  min-height: 250px;
  border-radius: 50%;
  background-size: 300px 300px;
  background-position: center;
  border: 5px solid var(--color-secondary);
}

.block-plans-vision .vision .image1 {
  background-image: url("https://images.squarespace-cdn.com/content/v1/6144be24b55acb0155121533/1632052402141-13P3Z4VPGNCFVK2K1V84/25677d50-2d92-48e2-9ad2-caa4bb3d3e8e+%282%29.jpg?format=750w");
}

.block-plans-vision .vision .image2 {
  background-image: url("https://images.squarespace-cdn.com/content/v1/6144be24b55acb0155121533/1634914779092-SQ1DCD1UBNM5J60D3YKH/76f69282-cf03-4a7a-b9d2-1028c3ce363c%2B%25282%2529.jpg?format=750w");
}

.block-plans-vision .vision .image3 {
  background-image: url("https://images.squarespace-cdn.com/content/v1/6144be24b55acb0155121533/1634914833265-XMDJ6FX3O9O2JWKDM2HU/6a4ea9a1-1485-4061-97d0-56063dae6811%2B%25281%2529.jpg?format=750w");
}
