/* 
grouped-elements INDEX
flex-row INDEX
input-field DONE
search-input DONE
material-symbols-outlined
nav-icon DONE
** logout-button-style NOT FOUND
search-button DONE
 */

.search-input {
  width: 70%;
}

.nav-icon {
  margin: 1rem;
}

.search-container input {
  margin: 0;
  font-size: 1.9rem;
  line-height: 2rem;
  width: 100%;
  padding: 0.5rem 1rem;
  border: 1px solid rgb(204, 204, 204);
  border-radius: 3px;
  /* margin-bottom: 2rem; */
  color: var(--color-primary);
}

.search-container form input::selection {
  background-color: var(--color-accent);
  -webkit-background-clip: text;
}

.search-container form input:-webkit-autofill {
  -webkit-text-fill-color: var(--color-primary);
  -webkit-background-clip: text;
}

.search-container form input::placeholder {
  color: var(--color-secondary);
}

.search-container form input:focus {
  outline: none;
}

/* Change the white to any color */
.search-container form input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset;
}
