.cookies-policy {
  word-break: break-word;
}

.cookies-policy a {
  color: var(--color-accent);
  text-decoration: none;
  font-weight: bold;
  cursor: pointer;
}

.cookies-policy a:link {
  color: var(--color-accent);
  text-decoration: none;
}

.cookies-policy a:active {
  color: var(--color-primary-text);
  text-decoration: none;
}

.cookies-policy a:hover {
  color: var(--color-primary-text);
  text-decoration: none;
}

.cookies-policy a:active {
  color: var(--color-primary-text);
  text-decoration: none;
}

.cookies-policy .cookie-settings-button {
  color: var(--color-accent);
  font-weight: bold;
  cursor: pointer;
}

.cookies-policy .cookie-settings-button:hover {
  color: var(--color-primary-text);
}
