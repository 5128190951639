.main-comments-section {
  width: 100%;
}

.main-comments-section ul {
  width: 100%;
}

.main-comments-section .name {
  font-size: 2.4rem;
  font-weight: 400;
}

.main-comments-section .comment {
  font-weight: 300;
}

.main-comments-section .date {
  font-size: 1.6rem;
  font-weight: 200;
}
