.cards-keynotes-mobile img {
  max-width: 100%;
  width: 100%;
  margin: 3rem auto 2rem;
}

.cards-keynotes-mobile h3,
.cards-keynotes-mobile .p--cards {
  padding: 0 1.5rem;
}
