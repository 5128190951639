.sub-comments {
  width: 100%;
}

.sub-comments ul {
  width: 100%;
  padding-left: 60px;
}

.sub-comments .name {
  font-size: 2.4rem;
  font-weight: 400;
}

.sub-comments .comment {
  font-weight: 300;
}

.sub-comments .date {
  font-size: 1.6rem;
  font-weight: 200;
}
