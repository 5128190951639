.about-page .about-image-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.about-page .about-image-container img {
  max-width: 80%;
}

.about-page .about-me-text {
  padding: 30px 0 0;
}

.about-page .testimonials {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.about-page .testimonial {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
  padding-top: 20px;
}

.about-page .testimonials .title {
  margin: 0;
  font-size: 2.2rem;
  font-weight: bold;
  padding-top: 30px;
}

.about-page .testimonials .summary {
  margin: 0;
  font-size: 1.6rem;
}

.about-page .testimonials .testimony {
  margin: 0;
  padding-top: 1rem;
}

.about-page .testimonials .person {
  margin: 0;
  font-weight: 600;
  padding-top: 0.5rem;
  font-size: 1.6rem;
}
