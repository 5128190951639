/* Icons */

.icon {
  width: 35px;
  height: 35px;
}
.icon--white {
  /* fill: white; */
  color: white;
}

.nav {
  display: block;
  width: 100%;
  position: fixed;
  z-index: 1;
  float: inline-start;
  overflow-y: scroll;

  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  background-color: var(--color-light-gray);
  padding: 0;
}

.nav__bar {
  width: 100%;
  padding: 10px 10px 5px;
  background-color: white;
}

.nav__toggler-container {
  display: flex;
  justify-content: space-between;
}

.nav__toggler-container .logo {
  max-width: 90px;
}

.nav__toggler-container .nav__icons--mobile {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-around;
}

.nav__media-icon {
  width: 25px;
  height: 25px;
  margin: 5px;
  color: var(--color-primary);
  cursor: pointer;
}

.nav__media-icon:hover {
  color: var(--color-secondary);
}

.nav__list {
  margin: 0;
  width: 100%;
  background-color: var(--color-primary);
}

.nav__item {
  padding: 0.5rem 2rem;
}

.nav__item button {
  color: white;
  transition: color 0.2s;
  width: 100%;
  text-transform: uppercase;
  font-weight: 700;
  text-decoration: none;
  text-align: right;
  padding-left: 2%;
  cursor: pointer;
}

.nav__item button:hover {
  color: var(--color-accent);
}

.nav .collapsible--expanded .nav__toggler {
  opacity: 1;
  border-radius: 5px;
  box-shadow: 0 0 6px 3px #666;
}

.nav__brand {
  max-height: 120px;
  cursor: pointer;
}

.collapsible__content {
  max-height: 0;
  /* even with max-height: 0 we still see text, so this overflow must be hidden */
  overflow: hidden;
  opacity: 0;
  /* all used here to animate all properties */
  transition: all 0.5s;
}

.collapsible--expanded .collapsible__chevron {
  transform: rotate(0);
}

.collapsible--expanded .collapsible__content {
  /* max-height used instead of height so that image doesn't take up all of viewport */
  max-height: 100vh;
  opacity: 1;
}

.ig-feed--side {
  display: none;
}

@media screen and (min-width: 768px) {
}

@media screen and (min-width: 1024px) {
  .nav {
    display: block;
    /* height fixed to allow for dropdown content */
    height: 140px;
    float: left;
    overflow-y: scroll;
    padding: 0 35px 10px;

    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    background-color: var(--color-light-gray);

    position: relative;
  }

  .nav__bar {
    padding: 0;
  }

  .nav__list {
    margin: 0;
    width: 100%;
    background-color: var(--color-light-gray);
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    max-width: 800px;
  }

  .nav__item {
    display: inline-block;
    padding: 1.5rem 0;
    max-width: 15%;
    justify-items: center;
    gap: 5px;
  }

  .nav__item button {
    color: var(--color-primary-text);
    text-transform: uppercase;
    font-weight: 700;
    transition: color 0.3s;
    text-decoration: none;
    text-align: center;
  }

  .nav__button button:hover {
    color: var(--color-accent);
  }

  .nav__brand {
    max-width: 100%;
    margin-top: 5rem;
    margin-bottom: 5rem;
    max-height: none;
  }

  .ig-feed--side {
    display: block;
  }
}

.dropdown {
  display: none;
  position: absolute;
  visibility: hidden;
  text-align: left;
  list-style: none;
  transition: all 0.8s;
  z-index: 2;
  background-color: white;
  border-radius: 0 0 5px 5px;
  padding-left: 16px;
  padding-bottom: 5px;
  padding-right: 16px;
  box-shadow: 0 -9px 0px 0px white, 12px 0 15px -4px #f3f3f3,
    -12px 0 15px -4px #f3f3f3;
  margin-top: 4px;
}

@media screen and (min-width: 1024px) {
  .dropdown li button {
    text-align: left;
    color: black;
  }

  ul li:hover > ul,
  ul li ul:hover {
    visibility: visible;
    opacity: 1;
    display: block;
  }

  .services-nav-item {
    min-width: 100px;
  }
}
