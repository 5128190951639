:root {
  --transition: all 0.3s linear;
  --spacing: 0.1rem;
  --radius: 0.25rem;
  --light-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  --dark-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
}

.slider-quote {
  margin: auto auto;
  /* margin-top: 10%; */
  text-align: center;
  max-width: 90%;
}

@media screen and (min-width: 992px) {
}
.slider-quote .title {
  text-align: center;
  margin-bottom: 2rem;
}
.slider-quote .title h2 {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
}
.slider-quote .title span {
  font-size: 0.85em;
  margin-right: 1rem;
  font-weight: 700;
}
.slider-quote .section-center {
  margin: auto auto;
  height: 300px;
  text-align: center;
  position: relative;
  display: flex;
  overflow: hidden;
}
.slider-quote .text {
  margin: 0 auto;
  margin-top: 1rem;
  line-height: 2.1rem;
  font-size: 1.6rem;
}
.slider-quote .icon {
  font-size: 3rem;
  margin-top: 1rem;
}
.slider-quote .prev,
.slider-quote .next {
  position: absolute;
  top: 200px;
  transform: translateY(-50%);
  width: 1.25rem;
  height: 1.25rem;
  display: grid;
  place-items: center;
  border-color: transparent;
  font-size: 1rem;
  border-radius: var(--radius);
  cursor: pointer;
  transition: var(--transition);
}
.slider-quote .prev:hover,
.slider-quote .next:hover {
}
.slider-quote .prev {
  left: 0;
}
.slider-quote .next {
  right: 0;
}
@media (min-width: 400px) {
  .slider-quote .section-center {
    height: 240px;
  }
}
@media (min-width: 768px) {
  .slider-quote .text {
    line-height: 2.1rem;
    font-size: 1.8rem;
  }
  .slider-quote .section-center {
    height: 300px;
  }
}
@media (min-width: 800px) {
  .slider-quote .prev,
  .slider-quote .next {
    width: 2rem;
    height: 2rem;
    font-size: 1.5rem;
  }
}
.slider-quote article {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  opacity: 0;
  transition: var(--transition);
}
.slider-quote article.activeSlide {
  opacity: 1;
  transform: translateX(0);
}
.slider-quote article.lastSlide {
  transform: translateX(-100%);
}
.slider-quote article.nextSlide {
  transform: translateX(100%);
}
