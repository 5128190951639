.about .about-quote-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.about .about-quote-container p {
  max-width: 80%;
  text-align: center;
  padding-top: 20px;
}

@media screen and (min-width: 768px) {
  .about .about-me-text {
    margin-left: 3rem;
  }
  .about .about-title {
    padding-left: 28px;
  }
}
