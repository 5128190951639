.spinner-container {
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  margin-top: 4rem;
}

.spinner-container--dots {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.spinner__loading {
  font-weight: 600;
  font-size: 2.5rem;
  margin-bottom: 0;
}

.dot {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background-color: var(--color-secondary);
}

.dot1 {
  animation: flash 1s infinite;
}

.dot2 {
  animation: flash 1s infinite;
  animation-delay: 0.333333333333s;
}

.dot3 {
  animation: flash 1s infinite;
  animation-delay: 0.666666666666s;
}

@keyframes flash {
  0% {
    width: 0px;
    height: 0px;
  }

  50% {
    width: 100%;
    height: 100%;
  }

  100% {
    width: 1%;
    height: 1%;
  }
}

.dot-container {
  width: 20px;
  height: 20px;
  margin: 40px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
