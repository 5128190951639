.audience {
  margin-bottom: 2.5rem;
}

.audience .h2 {
  text-align: center;
  margin-bottom: 5rem;
  margin-top: 6rem;
}

/* Cards */

.audience .card {
  border-radius: 7px;
  /* values: horizontal offset (0), vertical offset (0), blur/softness (20px), spread (10px), color */
  box-shadow: 0 0 20px 10px #f3f3f3;
  /* removes the sharp corners at the top of the header because without this the header is larger than the card element */
  overflow: hidden;
  margin: 10px;
}

.audience .card__header,
.audience .card__body {
  padding: 1rem 1.5rem;
}

/* if we have a .card__header inside a .card--primary element then the .card__header should be styled: */

.audience .card--secondary .card__header {
  background: var(--color-secondary);
  color: white;
}

.audience .card--secondary .badge--secondary {
  background: var(--color-primary);
}

@media screen and (min-width: 1024px) {
  .audience .card {
    /* margin: 30px; */
  }
}

.audience .plan__title {
  color: white;
  font-size: 1.8rem;
  text-align: center;
  font-weight: 600;
  margin: 0;
}

@media screen and (min-width: 768px) {
  .audience .card__header,
  .audience .card__body {
    padding: 2rem 3rem;
  }
  .audience .plan__title {
    font-size: 2.2rem;
  }
}

@media screen and (min-width: 768px) {
  .audience .card__header,
  .audience .card__body {
    padding: 2rem 3rem;
  }
  .audience .plan__title {
    font-size: 2.2rem;
  }
}
/* Plans */

.audience .plan {
  transition: transform 0.2s ease-out;
}

.audience .plan:hover {
  transform: scale(1.05);
}

/* Blocks */

.audience .block {
  /* variables can also be declared here, no only in route, accessible in all .block classes */
  --padding-vertical: 6rem;
  /* the variable can then be used in other situations */
  padding: var(--padding-vertical) 2rem;
}

.audience .block__header {
  text-align: center;
  /* margin-bottom: 4rem; */
}

.audience .block__heading {
  /* margin-top: 0; */
}

/* Plans Block */

.audience .grid {
  /* row gap : column gap */
  /* gap: 8rem 4rem; */
}

.audience .card {
  max-width: 700px;
  margin: 40px auto 0;
}

/* Lists */

.audience .list {
  /* to remove the default bullet point */
  list-style: none;
  /* to remove the indent that is set by default */
  padding-left: 0;
}

.audience .list--inline .list__item {
  display: inline-block;
  margin-right: 2rem;
  padding-bottom: 0.5rem;
}

.audience .list--tick {
  /* list-style-image: url(./assets/images/tick.svg); */
  padding-left: 0.5rem;
  /* color: var(--color-primary-text); */
  font-size: 1.4rem;
}

/* This is called nesting, we should try to avois it because it is very specific but it has its uses */
/* Here the styles are applied to the list items with class of list__item within the list element with class list--tick */
/* So, for lists with a tick skin, our items should have ... */
.audience .list--tick .list__item {
  padding-left: 0.5rem;
  /* margin-bottom: 1rem; */
}

@media screen and (min-width: 1024px) {
  .audience .list--tick .list__item {
    padding-left: 0;
  }
}

.audience .check {
  color: var(--color-accent);
  padding-top: 0.5rem;
  font-size: 2rem;
  margin-right: 1.6rem;
}
