/* @import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap"); */

.carousel-container {
  width: 100%;
  max-width: 100%;
  overflow: hidden;
  padding-top: 30px;
}

.carousel-container .carousel {
  display: flex;
  transition: all 0.35s ease 0s;
  height: 270px;
}

/* Change this to alter size of images in carousel */
.carousel-container .carousel-card {
  width: calc(100%);
  min-width: calc(100%);
  height: 200px;
  /* This can be removed to have images close to eachother */
  padding: 0 10px;
}

@media screen and (min-width: 700px) {
  .carousel-container .carousel-card {
    width: calc(100% / 2);
    min-width: calc(100% / 2);
    height: 200px;
    /* This can be removed to have images close to eachother */
    padding: 0 50px;
  }
}

@media screen and (min-width: 1024px) {
  .carousel-container .carousel-card {
    width: calc(100% / 3);
    min-width: calc(100% / 3);
  }
}

.carousel-container .carousel-card-content {
  position: relative;
  width: 100%;
  height: 100%;
  background-size: cover;
  transition: transform 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
  background-position: center;
  border-radius: 10px;
}

@media screen and (min-width: 700px) {
  .carousel-container .carousel-card-content {
    position: relative;
    width: 112%;
    height: 100%;
    background-size: cover;
    transition: transform 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
    background-position: center;
    border-radius: 10px;
  }
}

.carousel-container .carousel-card-title {
  position: relative;
  color: #e5e5e5;
  top: 50%;
  font-size: 3.3rem;
  font-weight: 700;
  transition: all 0.8s ease 0.3s;
}

.carousel-container .dots {
  display: flex;
  justify-content: center;
  margin-top: 40px;
}

.carousel-container .dot {
  display: inline-block;
  height: 7px;
  width: 7px;
  border-radius: 50%;
  border: none;
  padding: 0;
}

.carousel-container .dot:hover {
  cursor: pointer;
}

.carousel-container .dot:not(:last-child) {
  margin-right: 10px;
}

.carousel-container .dot.active {
  background-color: #c9c9c9;
}

.carousel-container .dot:not(.active) {
  background-color: #545454;
}

.carousel-container .button-group {
  display: flex;
  justify-content: center;
  margin-top: 30px;
}

.carousel-container .button-group > * {
  height: 32px;
  width: 77px;
  border: none;
}

.carousel-container .button-group button {
  background-color: var(--color-secondary);
  color: white;
  border-radius: 10px;
}

.carousel-container .button-group button:hover {
  cursor: pointer;
}

.carousel-container .button-group button:disabled {
  opacity: 0.5;
}

.carousel-container .button-group button:is(:first-child) {
  margin-right: 20px;
}

.carousel-container .carousel-card-text {
  width: 100%;
}

.carousel-container .carousel-card-content .link {
  color: var(--color-primary-text);
  text-decoration: none;
  font-weight: bold;
}

.carousel-container .carousel-card-content .link:link {
  color: var(--color-primary-text);
  text-decoration: none;
}

.carousel-container .carousel-card-content .link:active {
  color: var(--color-accent);
  text-decoration: none;
}

.carousel-container .carousel-card-content .link:hover {
  color: var(--color-accent);
  text-decoration: none;
}

.carousel-container .carousel-card-content .link:active {
  color: var(--color-accent);
  text-decoration: none;
}

.carousel-container .carousel-card-content .card-image {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
}
