.title-caption {
  text-align: center;
  font-weight: 600;
  color: var(--color-primary);
  padding-top: 8px;
  font-size: 1.3rem;
  line-height: 1.8rem;
}

@media screen and (min-width: 768px) {
  .title-caption {
    font-size: 1.4rem;
    line-height: 1.9rem;
  }
}
@media screen and (min-width: 1024px) {
  .title-caption {
    max-width: 530px;
    margin: 0 auto;
  }
}

@media screen and (min-width: 1110px) {
  .title-caption {
    max-width: none;
  }
}

@media screen and (min-width: 1350px) {
  .title-caption {
    font-size: 1.6rem;
    line-height: 2.1rem;
  }
}
