:root {
  /* --color-primary: #151a61; */
  /* --color-primary: darkolivegreen; */
  /* --color-primary: hsl(82, 39%, 25%); */
  /* --color-primary: #588061; */
  /* --color-primary: #577f60; */
  /* Mary's original colour */
  --color-primary: hsl(93, 40%, 20%);
  --color-primary: rgb(83, 121, 92);
  /* --color-primary: #253617; */
  /* --color-primary: #3e363f; */
  --color-primary-pdf: #3840a8;
  --color-primary-hover: #151a61;
  /* --color-primary-text: #426149; */
  --color-primary-text: rgb(58, 85, 64);
  --color-primary-text: #253617;
  --color-primary-text: black;

  --color-secondary: #8cb194;

  --color-secondary: #97a588;
  /* --color-secondary: #97a588; current secondary colour */
  /* --color-secondary: darkolivegreen; */

  --color-secondary-hover: #38a8ce;

  --color-accent: #e5c648;
  --color-accent2: rgb(187, 160, 124);
  --color-accent: #d6b11f;

  --color-accent-hover: #a3d063;

  --color-light-grey: #fefefd;

  --toastify-color-light: #fff;
  --toastify-color-dark: #121212;
  --toastify-color-info: #3498db;
  /* --toastify-color-success: #07bc0c; */
  --toastify-color-success: #8cb194 !important;
  --toastify-color-warning: #f1c40f;
  --toastify-color-error: #e74c3c;
  --toastify-color-transparent: rgba(255, 255, 255, 0.7);
}

/* Elements */

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif; */
  /* font-family: "Open Sans", sans-serif; */
  font-family: "Poppins", sans-serif;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--color-primary-text);
  font-size: 1.5rem;
  line-height: 2.7rem;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

*,
*::after,
*::before {
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
}

h1,
h2,
h3,
p,
ul,
li {
  margin: 0;
}

p {
  margin-bottom: 2rem;
}

button {
  padding: 0;
  border-style: none;
  background-color: transparent;
  color: var(--color-primary);
}

a {
  /* color: white; */
  /* color: grey; */
}
a {
  /* color: black; */
  text-decoration: none;
  font-weight: bold;
}

a:link {
  /* color: black; */
  text-decoration: none;
}

a:active {
  /* color: black; */
  text-decoration: none;
}

a:hover {
  color: var(--color-accent);
  text-decoration: none;
}

/* Headings */
.content {
  padding: 15px;
  max-width: 1200px;
  margin: 0 auto;
}

@media screen and (min-width: 768px) {
  .content {
    padding: 20px;
  }
}

@media screen and (min-width: 1024px) {
  .content {
    padding: 35px;
    margin-top: 80px;
  }
}

.h1 {
  color: var(--color-primary);
  text-transform: uppercase;
  font-size: 2.9rem;
  line-height: 3.45rem;
  font-weight: 700;
  text-align: center;
  width: 100%;
}

.h2 {
  font-size: 2.4rem;
  line-height: 3rem;
  font-weight: 700;
  margin-bottom: 2rem;
}

.h3 {
  font-size: 1.8rem;
  line-height: 2.3rem;
  font-weight: 700;
  margin-bottom: 2rem;
}

@media screen and (min-width: 550px) {
  .h1 {
    font-size: 3.2rem;
    line-height: 3.8rem;
  }
  .h2 {
    font-size: 2.7rem;
    line-height: 3.3rem;
  }
  .h3 {
    font-size: 2rem;
    line-height: 2.5rem;
  }
}

@media screen and (min-width: 768px) {
  .h1 {
    /* font-size: 3.5rem; */
    font-size: 4rem;
    line-height: 4.2rem;
  }
  .h2 {
    font-size: 3.1rem;
    line-height: 3.7rem;
  }
  .h3 {
    font-size: 2.3rem;
    line-height: 2.8rem;
  }
}

@media screen and (min-width: 1350px) {
  .h1 {
    font-size: 4.8rem;
    line-height: 5.8rem;
  }
  .h2 {
    font-size: 3.3rem;
    line-height: 3.9rem;
  }
  .h3 {
    font-size: 2.6rem;
    line-height: 3.1rem;
  }
}

/* Paragraphs */

.p {
}

.p--tiny {
  font-size: 0.9rem;
  line-height: 1.6rem;
}

.p--small {
  font-size: 1.1rem;
  line-height: 1.6rem;
}

.p--medium {
  font-size: 1.4rem;
  line-height: 1.8rem;
}

.p--medium-large {
  font-size: 1.5rem;
  line-height: 2rem;
}

.p--18 {
  font-size: 1.6rem;
  line-height: 2.1rem;
}

.p--large {
  font-size: 1.8rem;
  line-height: 2.3rem;
}

.p--very-large {
  font-size: 4rem;
  line-height: 4.5rem;
}

@media screen and (min-width: 550px) {
  .p--small {
    font-size: 1.1rem;
    line-height: 1.6rem;
  }

  .p--medium {
    font-size: 1.4rem;
    line-height: 2.1rem;
  }

  .p--medium-large {
    font-size: 1.6rem;
    line-height: 2.2rem;
  }

  .p--18 {
    font-size: 1.7rem;
    line-height: 2.2rem;
  }

  .p--large {
    font-size: 2rem;
    line-height: 2.5rem;
  }

  .p--very-large {
    font-size: 4.5rem;
    line-height: 5rem;
  }
}

@media screen and (min-width: 768px) {
  .p--small {
    font-size: 1.2rem;
    line-height: 1.8rem;
  }

  .p--medium {
    font-size: 1.6rem;
    line-height: 2.6rem;
  }

  .p--medium-large {
    font-size: 1.7rem;
    line-height: 2.3rem;
  }

  .p--18 {
    font-size: 1.8rem;
    line-height: 2.4rem;
  }

  .p--large {
    font-size: 2.2rem;
    line-height: 3rem;
  }

  .p--very-large {
    font-size: 5.1rem;
    line-height: 5.6rem;
  }
}

.p--centered {
  text-align: center;
}

.p--bold {
  font-weight: 700;
}

.p--bold--important {
  font-weight: 700 !important;
}

.p--underline {
  text-decoration: underline;
}

.p--divider {
  color: var(--color-accent);
  margin: 0 1rem;
}

.p--inline {
  display: inline-block;
  margin-bottom: 0;
}

.p--template-literal {
  white-space: pre-line;
}

.p--no-break {
  margin-bottom: 0;
}
/* Breaks */

.p--break {
  margin-bottom: 2.5rem;
}

.block-break {
  margin-bottom: 5rem;
}

.br--mobile {
}

.br--tablet {
}

.divider--mobile {
  display: none;
}

@media screen and (min-width: 768px) {
  .br--tablet {
    display: none;
  }
}

/* @media screen and (min-width: 1024px) { */
/* special breakpoint here just for the contacts page text */
@media screen and (min-width: 1250px) {
  .br--mobile {
    display: none;
  }
  .divider--mobile {
    display: inline;
  }
}

/* Blocks */

.block {
  margin-bottom: 4rem;
}

/* Lists */

.list {
  list-style: none;
  padding-left: 0;
  font-size: 1.9rem;
  line-height: 2.7rem;
}

.list--bullet {
  padding-left: 2rem;
  font-size: 1.9rem;
  line-height: 2.7rem;
}

.list--numbered li {
  margin: 0.8rem 0;
}

/* .list--bullet > li {
  font-size: 1.9rem;
  line-height: 2.7rem;
} */

.list--inline .list__item {
  display: inline-block;
  margin-right: 2rem;
}

/* Grids */

.grid {
  display: grid;
  /* max-width: 100%; */
  gap: 15px;
}

.grid__image {
  justify-self: center;
  width: 80%;
  margin-bottom: 25px;
}

@media screen and (min-width: 768px) {
  /* this class only exists for tablets+ */
  .grid--1x2 {
    grid-template-columns: 1fr 1fr;
  }
  .grid--1x2--32 {
    grid-template-columns: 3fr 2fr;
  }
  .grid--1x2--25 {
    grid-template-columns: 2fr 5fr;
  }
  .grid--1x2--52 {
    grid-template-columns: 5fr 2fr;
  }
  .grid--1x2--23 {
    grid-template-columns: 2fr 3fr;
  }
  .grid--1x2x3 {
    grid-template-columns: 1fr 1fr;
  }
  .grid--1x4 {
    grid-template-columns: 1fr 1fr;
  }
  .grid--1x4--wide {
    grid-template-columns: 1fr 1fr;
  }
  .grid__image {
    width: 100%;
    margin-left: 25px;
  }
}

@media screen and (min-width: 1024px) {
  /* this class only exists for tablets+ */
  .grid--1x2x3 {
    grid-template-columns: 1fr 1fr 1fr;
  }
  .grid--1x3 {
    grid-template-columns: 1fr 1fr 1fr;
  }
  .grid--1x4 {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
  .grid--1x4--wide {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

@media screen and (min-width: 1200px) {
  /* this class only exists for tablets+ */
  .grid--1x2-1200 {
    grid-template-columns: 1fr 1fr;
  }
}

@media screen and (min-width: 1440px) {
  .grid--1x4--wide {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}

/* Lines */

.line--secondary {
  height: 1px;
  background-color: var(--color-secondary);
  width: 90%;
  margin: 5rem 0 5rem;
  margin-left: 5%;
}

/* gradients */

.gradient-bottom--accent {
  height: 5rem;
  background-image: linear-gradient(
    to top,
    rgba(187, 160, 124, 0),
    rgb(187, 160, 124, 1)
  );
}
.gradient-top--accent {
  height: 5rem;
  background-image: linear-gradient(
    to bottom,
    rgba(187, 160, 124, 0),
    rgb(187, 160, 124, 1)
  );
}
.gradient-bottom--primary {
  height: 5rem;
  background-image: linear-gradient(
    to top,
    rgba(83, 121, 92, 0),
    rgb(83, 121, 92, 1)
  );
}
.gradient-top--primary {
  height: 5rem;
  background-image: linear-gradient(
    to bottom,
    rgba(83, 121, 92, 0),
    rgb(83, 121, 92, 1)
  );
}
.gradient-bottom--secondary {
  height: 5rem;
  background-image: linear-gradient(
    to top,
    rgba(140, 177, 148, 0),
    rgb(140, 177, 148, 1)
  );
}
.gradient-top--secondary {
  height: 5rem;
  background-image: linear-gradient(
    to bottom,
    rgba(140, 177, 148, 0),
    rgb(140, 177, 148, 1)
  );
}

/* Forms */

.label {
  font-size: 1.5rem;
  line-height: 2.2rem;
}

/* Quotes  */

.quote {
  /* display: flex; */
  width: 100%;
  background: url("https://www.patricktomlinson.com/images/testimonial-bg.png")
    top left no-repeat;
  background-size: 3.5rem 2.8rem;
  padding: 0 0 0 50px;
}

.quote__description {
  padding: 0 0 0 50px;
}

/* .quote__quote-marks {
  margin-right: 1rem;
  color: var(--color-accent);
  font-size: 5rem;
  margin-top: -2rem;
} */

/* Functional Classes */

.flex {
  display: flex;
  width: 100%;
}

.flex--centered {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  /* added for the next buttons */
  flex-wrap: wrap;
}

.flex--spaced {
  justify-content: space-around;
}

.flex--column {
  flex-direction: column;
}

/* Buttons */

.button {
  font-size: 2.1rem;
  line-height: 3rem;
  font-weight: 700;
  /* font-size: 31px; */
  /* line-height: 37px; */
  padding: 0.6rem 2.2rem;
  width: fit-content;
  margin: 10px;
  border-radius: 3px;
}

.button--round {
  border-radius: 30px;
}

/* .button--accent {
  color: white;
  background-color: var(--color-accent);
} */

.button--accent {
  color: white;
  background-color: var(--color-primary-text);
  border: 1px solid var(--color-secondary);
}

.button--accent:hover {
  background-color: var(--color-primary);
  transition: 0.5s;
}

.button--primary {
  color: white;
  background-color: var(--color-primary);
  border: 1px solid var(--color-primary);
}

.button--primary:hover {
  background-color: var(--color-secondary);
  transition: 0.5s;
}

.button--secondary {
  color: white;
  background-color: var(--color-secondary);
  border: 1px solid var(--color-secondary);
}

.button--secondary:hover {
  color: #000000;
  background-color: #ffffff;
  transition: 0.5s;
}

.button--white {
  color: var(--color-primary-text);
  background-color: white;
  border: 1px solid var(--color-secondary);
}

.button--white:hover {
  background-color: var(--color-secondary);
  color: white;
  transition: 0.5s;
  border: 1px solid var(--color-primary);
}

.button--white-inverse {
  color: white;
  border: 1px solid var(--color-accent);
  /* VERSION2 */
  background-color: var(--color-accent);
  /* border: 1px solid black; */
  /* border: none; */
}

.button--white-inverse:hover {
  background-color: white;
  color: var(--color-primary-text);
  transition: 0.5s;
  border: 1px solid var(--color-secondary);
  /* VERSION2 */
  border: 1px solid black;
}

.button--white-inverse-clicked {
  background-color: white;
  color: var(--color-primary-text);
  transition: 0.5s;
  border: 1px solid var(--color-secondary);
  /* VERSION2 */
  border: 1px solid black;
}

.button--disabled {
  color: var(--color-primary);
  background-color: rgb(204 204 204);
}

.button--small {
  font-size: 1.9rem;
  line-height: 2.7rem;
}

.download-button {
  border: none;
  background-color: transparent;
  padding: none;
  margin: none;
  margin-top: 2rem;
  margin-bottom: 3rem;
  color: var(--color-accent);
  font-size: 2.1rem;
  line-height: 3rem;
  font-weight: 700;
  text-decoration: none;
}

.download-button:hover {
  color: var(--color-primary);
}

.download-button--inline {
  margin: 0;
  margin-left: 2rem;
}
/* Text-wrap-image */

.text-wrap-image__img {
  /* float: none; */
  display: block;
  /* without width:100% some images weren't resizing on very small screens */
  width: 100%;
  max-width: 90%;
  margin: 25px auto;
  /* display: block; */
  min-width: 300px;
}

@media screen and (min-width: 768px) {
  .text-wrap-image__img {
    max-width: 350px;
    /* new */
    min-width: 350px;
    margin: 5px 5px 25px 35px;
    float: right;
  }
}

@media screen and (min-width: 1024px) {
  .text-wrap-image__img {
    float: right;
    max-width: 40%;
    margin: 5px 5px 25px 35px;
    /* new */
    min-width: 300px;
  }
}

.text-wrap-image__p {
  /* text-align: justify; */
}

.mobile--block {
  display: block;
}

.mobile--inline {
  display: inline-block;
}

.tablet--block {
  display: none;
}

.tablet--inline {
  display: none;
}

.mobile-tablet--block {
  display: block;
}

.mobile-tablet--inline {
  display: inline-block;
}

.tablet-desktop--block {
  display: none;
}

.tablet-desktop--inline {
  display: none;
}

.desktop--block {
  display: none;
}

.desktop--inline {
  display: none;
}

@media screen and (min-width: 768px) {
  .mobile--block {
    display: none;
  }

  .mobile--inline {
    display: none;
  }

  .tablet--block {
    display: block;
  }

  .tablet--inline {
    display: inline-block;
  }

  .mobile-tablet--block {
    display: block;
  }

  .mobile-tablet--inline {
    display: inline-block;
  }

  .tablet-desktop--block {
    display: block;
  }

  .tablet-desktop--inline {
    display: inline-block;
  }

  .desktop--block {
    display: none;
  }

  .desktop--inline {
    display: none;
  }
}

@media screen and (min-width: 1024px) {
  .mobile--block {
    display: none;
  }

  .mobile--inline {
    display: none;
  }

  .tablet--block {
    display: none;
  }

  .tablet--inline {
    display: none;
  }

  .mobile-tablet--block {
    display: none;
  }

  .mobile-tablet--inline {
    display: none;
  }

  .tablet-desktop--block {
    display: block;
  }

  .tablet-desktop--inline {
    display: inline-block;
  }

  .desktop--block {
    display: block;
  }

  .desktop--inline {
    display: inline-block;
  }
}
