.block-plans-working-with .grid {
  padding-top: 20px;
}

.block-plans-working-with .h2 {
  padding-top: 20px;
}
.block-plans-working-with .h2 {
  text-align: center;
  margin-bottom: 2.5rem;
}

/* Cards */

.block-plans-working-with .card {
  border-radius: 7px;
  box-shadow: 0 0 20px 10px #f3f3f3;
  overflow: hidden;
  margin: 10px;
}

.block-plans-working-with .card__header,
.block-plans-working-with .card__body {
  padding: 1rem 1.5rem;
}

.block-plans-working-with .card--secondary .card__header {
  background: var(--color-secondary);
  color: white;
}

.block-plans-working-with .card--secondary .badge--secondary {
  background: var(--color-primary);
}

.block-plans-working-with .plan__title {
  color: white;
  font-size: 1.8rem;
  text-align: center;
  font-weight: 600;
  margin: 0;
}

@media screen and (min-width: 768px) {
  .block-plans-working-with .card__header,
  .block-plans-working-with .card__body {
    padding: 2rem 3rem;
  }
  .block-plans-working-with .plan__title {
    font-size: 2.2rem;
  }
}

@media screen and (min-width: 1024px) {
  .block-plans-working-with .card {
    margin: 30px;
  }
  .block-plans-working-with .grid--1x3 .card__header {
    min-height: 94px;
  }
}

/* Plans */

.block-plans-working-with .plan {
  transition: transform 0.2s ease-out;
}

.block-plans-working-with .plan:hover {
  transform: scale(1.05);
}

/* Blocks */

.block-plans-working-with .block {
  /* variables can also be declared here, no only in route, accessible in all .block classes */
  /* --padding-vertical: 6rem; */
  /* the variable can then be used in other situations */
  padding: 4rem 0rem 0;
}

.block-plans-working-with .block__header {
  text-align: center;
  margin-bottom: 4rem;
}

.block-plans-working-with .block__heading {
  margin-top: 0;
}

/* Plans Block */

.block-plans-working-with .grid {
  gap: 4rem 4rem;
}

.block-plans-working-with .card {
  margin: 0 auto;
  height: 100%;
}
